import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import * as JsSearch from 'js-search'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { LinkAnchor } from '../../components/LinkAnchor'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
  AffinityProvider,
  Button,
  InputGroupSearch,
} from '@allied-solutions/affinity'
import { camelCase } from 'lodash'
import { Helmet } from 'react-helmet'
import styled, { css } from 'styled-components'
import {
  Box,
  ButtonAnchor,
  Container,
  Icon,
  Pill,
  P,
  Ul,
  H2,
  H1,
  H3,
} from '@allied-solutions/affinity'
import { UpArrow } from '@allied-solutions/affinity-react-icons/dist/24px'
import { Leave } from '@allied-solutions/affinity-react-icons/dist/48px'

import { useWindowWidth, useDisabledButtonMessaging } from '../../hooks'
import { theme, dark } from '../../theme.js'
import { Layout, Nav, ArticleGrid } from '../../components'
import { BottomOfFooter } from '../../components/SiteLayout/Footer'
import computer from './Computer.svg'
import phone from './Phone.svg'

const SearchlistItem = styled.li`
  padding: 0.1rem 2rem 0.8rem 1.75rem !important;
  border-left: 3px solid transparent;
  transition: background-color 0.3s ease, border-left-color 0.3s ease;
  ${props => css`
    &:hover {
      background-color: ${props => props.theme.colors.gray20};

      border-left: 3px solid ${props => props.theme.colors.primary};

      color: ${props.theme.colors.primary};
      ${P} {
        color: ${props.theme.colors.primary};
      }
    }
  `}
`
const SupportPage = ({ data }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [rawSupportData, setRawSupportData] = useState([])
  const [rawArticleData, setRawArticleData] = useState([])
  const [searchableSupportData, setSearchableSupportData] = useState([])
  const [searchSupportResults, setSearchSupportResults] = useState([])
  const [searchableArticleData, setSearchableArticleData] = useState([])
  const [searchArticleResults, setSearchArticleResults] = useState([])
  const [resultsOpen, setResultsOpen] = useState(false)
  const [ctaHeight, setCtaHeight] = useState(undefined)
  const windowWidth = useWindowWidth()
  const {
    liveChatDisabled,
    phoneDisabled,
    chatWillOpenTime,
    phoneWillOpenTime,
  } = useDisabledButtonMessaging()

  const largeBreakpoint = theme[2].replace('px', '')
  const mediumBreakpoint = theme[1].replace('px', '')

  useEffect(() => {
    setCtaHeight(document.getElementById('ctaBox').offsetHeight)
  }, [windowWidth])

  useEffect(() => {
    setRawSupportData(data.support.frontmatter.FAQs)

    setRawArticleData(
      data.articles.nodes.map(object => {
        return object.frontmatter
      })
    )
  }, [data.support.frontmatter.FAQs, data.articles.nodes])

  useEffect(() => {
    rebuildIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawSupportData, rawArticleData])

  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search(['question'])

    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex()
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()

    dataToSearch.addIndex('question')
    dataToSearch.addIndex('answer')

    dataToSearch.addDocuments(rawSupportData)
    setSearchableSupportData(dataToSearch)

    const secondSearch = new JsSearch.Search(['title'])

    secondSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    secondSearch.searchIndex = new JsSearch.TfIdfSearchIndex()
    secondSearch.sanitizer = new JsSearch.LowerCaseSanitizer()

    secondSearch.addIndex('title')

    secondSearch.addDocuments(rawArticleData)
    setSearchableArticleData(secondSearch)
    //console.log(secondSearch)
  }

  const handleSearch = event => {
    const query = event.target.value
    const queryResult1 = searchableSupportData.search(query)
    const queryResult2 = searchableArticleData.search(query)
    setSearchQuery(query)
    setSearchSupportResults(queryResult1)
    setSearchArticleResults(queryResult2)
    setResultsOpen(true)

    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ searchInput: query })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setResultsOpen(true)
    //custom search form submit
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'custom search form submit',
      })
    }
  }

  const renderSupportResults = () => {
    return searchSupportResults.map(result => {
      return (
        <SearchlistItem>
          <LinkAnchor
            to={`/support/#${camelCase(result.question)}`}
            className={`Support__Search__FAQResults--${camelCase(
              result.question
            )}`}
            onAnchorLinkClick={() => setResultsOpen(false)}
          >
            <P
              mb={0}
              typeStyle="bodyLarge"
              textAlign="left"
              lineHeight="28px"
              display="block"
              color="gray1000"
              css={`
                & + & {
                  margin-top: ${props => props.theme.space[20]};
                }

                &:first-child {
                  margin-top: ${props => props.theme.space[4]};
                }
              `}
            >
              {result.question}
            </P>
          </LinkAnchor>
        </SearchlistItem>
      )
    })
  }

  const renderArticleResults = () => {
    return searchArticleResults.map(result => {
      return (
        <SearchlistItem>
          <LinkAnchor
            to={result.path}
            className={`Support__Search__ArticleResults--${camelCase(
              result.title
            )}`}
          >
            <P
              mb={0}
              typeStyle="bodyLarge"
              textAlign="left"
              lineHeight="28px"
              display="block"
              color="gray1000"
              css={`
                & + & {
                  margin-top: ${props => props.theme.space[20]};
                }

                &:first-child {
                  margin-top: ${props => props.theme.space[4]};
                }
              `}
            >
              {result.title}
            </P>
          </LinkAnchor>
        </SearchlistItem>
      )
    })
  }

  return (
    <Layout>
      <Helmet>
        <title>{data.support.frontmatter.metaTitle}</title>
        <meta
          name="description"
          content={data.support.frontmatter.metaDescription}
        />
      </Helmet>
      <Box
        height="100px"
        width="100%"
        position="absolute"
        zIndex="100"
        css={`
          ${props => props.theme.mediaQueries.lg} {
            background-color: white;
          }
          @media only screen and (min-width: 1380px) {
            background-color: transparent;
          }
        `}
      >
        <Nav underlineSupport liColor="gray1000" />
      </Box>
      <main>
        <Box
          bg="lightBlue200"
          height={['292px', '292px', '352px']}
          width="100%"
          borderBottomLeftRadius={['none', 'none', '160px']}
          textAlign="center"
        >
          <H2
            className="h2"
            typeStyle="h2"
            css={`
              padding-top: ${props => props.theme.space[40]};
            `}
          >
            Support
          </H2>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box position="relative" width={['86%', '86%', '50%']} mx="auto">
            <InputGroupSearch
              id="Support__SearchSection--searchInput"
              type="search"
              autoComplete="off"
              value={searchQuery}
              onChange={handleSearch}
              boxShadow="xl"
              borderColor="#E8EDF5"
              marginTop={'-30px'}
              placeholder={`${
                windowWidth >= largeBreakpoint
                  ? 'Search resources (e.g. deductible, lienholder)'
                  : 'Search'
              }`}
              css={css({
                paddingLeft: '16px !important',
                paddingRight: '12px !important',
                fontSize: '16px !important',
                fontStyle: 'italic !important',
                // fontWeight: '300 !important',
                letterSpacing: '0.25px ! important',
                lineHeight: '26px !important',
              })}
            />

            {resultsOpen &&
            ((searchSupportResults.length > 0 && searchQuery.length > 2) ||
              (searchArticleResults > 0 && searchQuery.length > 2)) ? (
              <Box
                bg="white"
                boxShadow="xl"
                mt="5"
                width="100%"
                position="absolute"
                p="8"
                borderRadius="3"
                zIndex="5"
                maxHeight="400px"
                overflowY="auto"
              >
                <Ul>
                  {searchArticleResults.length > 0 ? (
                    <>
                      <li>
                        <P
                          mb={0}
                          fontWeight={900}
                          textAlign="left"
                          color="darkGray"
                        >
                          Articles:
                        </P>
                      </li>
                      <Ul mb="8">{renderArticleResults()}</Ul>
                    </>
                  ) : null}
                  {searchSupportResults.length > 0 ? (
                    <>
                      <li>
                        <P
                          mb={0}
                          fontWeight={900}
                          textAlign="left"
                          color="darkGray"
                        >
                          FAQs:
                        </P>
                      </li>
                      <Ul>{renderSupportResults()}</Ul>
                    </>
                  ) : null}
                </Ul>
              </Box>
            ) : null}
          </Box>
        </form>
        <Container pt={20} pb={40}>
          <ArticleGrid />
        </Container>
        <Box width={['86%', '86%', '80%']} mx="auto">
          <Box
            minWidth={[null, null, '292px']}
            width={[null, null, '292px']}
            mb={['8', '8', '0']}
            position={[null, null, 'sticky']}
            top="150px"
          >
            <H1
              id="faq"
              aria-labelledby="questions and answers"
              className="h2"
              typeStyle="h2"
              css={`
                line-height: 56px;
              `}
            >
              Questions & Answers
            </H1>
            <LinkAnchor
              to="/support/#quickLinks"
              className="Support__FAQSection--backToTop"
            >
              <Box
                mt="18"
                display={['none', 'none', 'flex']}
                alignItems="center"
              >
                <Box
                  bg="blue800"
                  width="12"
                  height="12"
                  borderRadius="full"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon src={UpArrow} color="white" />
                </Box>
                <P
                  mb={0}
                  ml="3"
                  typeStyle="labelMedium"
                  fontWeight="bold"
                  textAlign="left"
                  color="blue1000"
                >
                  Back to top
                </P>
              </Box>
            </LinkAnchor>
          </Box>
          <Box
            ml={[null, null, '45%']}
            maxWidth={[null, null, '748px']}
            mt={[null, null, '-225px']}
          >
            <P
              mb={0}
              as="labelMedium"
              color="#00173E"
              lineHeight="24px"
              fontWeight="500"
              textAlign="left"
              id="quickLinks"
            >
              Quick Links
            </P>
            <Ul
              css={`
                list-style-type: disc;
                li > a {
                  display: flex;
                }
              `}
            >
              {data.support.frontmatter.FAQs.map((FAQ, index) => {
                return (
                  <li
                    key={camelCase(FAQ.question)}
                    css={`
                      margin-top: ${props => props.theme.space[4]};
                      &:first-child {
                        margin-top: ${props => props.theme.space[3]};
                      }
                    `}
                  >
                    <LinkAnchor
                      to={`/support/#${camelCase(FAQ.question)}`}
                      className={`Support__FAQSection__FAQs--${camelCase(
                        FAQ.question
                      )}`}
                    >
                      <P
                        mb={0}
                        typeStyle="bodyLarge"
                        textAlign="left"
                        color="blue800"
                      >
                        {FAQ.question}
                      </P>
                    </LinkAnchor>
                  </li>
                )
              })}
            </Ul>
            <Box height="1px" bg="gray20" mt="14" mb="12" />
            {data.support.frontmatter.FAQs.map(FAQ => {
              return (
                <Box
                  key={camelCase(FAQ.question)}
                  css={`
                    & + & {
                      margin-top: ${props => props.theme.space[10]};
                    }
                  `}
                >
                  <H2
                    typeStyle="titleSmall"
                    fontWeight="bold"
                    id={camelCase(FAQ.question)}
                  >
                    {FAQ.question}
                  </H2>
                  <P
                    mb={0}
                    css={`
                      a {
                        text-decoration: underline;
                      }
                    `}
                    updated
                    to
                    typeStyle="bodyLarge"
                    textAlign="left"
                    color="gray600"
                  >
                    <MDXRenderer>{FAQ.answer}</MDXRenderer>
                  </P>
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box mt="200px" id="contactSection">
          <H2
            className="h3"
            typeStyle="h3"
            lineHeight="54px"
            css={css({ px: '7%', textAlign: 'center' })}
          >
            Reach out for help
          </H2>
          <Box
            display={[null, null, 'flex']}
            width={['86%', '86%', '80%']}
            mx="auto"
            mt="19"
            mb="200px"
            css={`
              justify-content: space-around;
            `}
          >
            <Box width={[null, null, '456px']} mt="14">
              <Box
                height="375px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <img
                    src={computer}
                    alt="using a computer"
                    css={`
                      display: block;
                      margin: 13px 0 32px 0;
                    `}
                  />
                  <H3
                    className="h4"
                    typeStyle="h4"
                    css={css({ mt: 7, textAlign: 'center' })}
                  >
                    Live Chat
                  </H3>
                  <Pill bg="lightBlue1000">
                    <P
                      mb={0}
                      typeStyle="labelSmall"
                      textAlign="center"
                      color="#FFFFFF"
                    >
                      {liveChatDisabled ? 'Closed' : 'Open'}
                    </P>
                  </Pill>
                  <P
                    mb={0}
                    typeStyle="bodyLarge"
                    textAlign="center"
                    color="gray600"
                    display="block"
                    mt="6"
                  >
                    Mon. through Fri. 8:00 AM - 5:00 PM EST
                    <br />
                    Closed Sat., Sun., and holidays
                  </P>
                </Box>
                <Box textAlign="center">
                  <ButtonAnchor
                    outlined
                    id="HelpMenu__Button--StartChat"
                    display="inline-block"
                    rounded
                    target="_blank"
                    rel="noreferrer"
                    href={process.env.GATSBY_CHAT_URL}
                    disabled={liveChatDisabled}
                  >
                    {liveChatDisabled ? 'Chat Unavailable' : 'Start a Chat'}
                  </ButtonAnchor>
                </Box>
              </Box>
              {liveChatDisabled && (
                <P
                  mb={0}
                  mt="4"
                  display="block"
                  typeStyle="bodySmall"
                  textAlign="center"
                  id="supportPage--text--chatClosedMessage"
                >
                  {`Chat support will open ${chatWillOpenTime}`}
                </P>
              )}
            </Box>
            {windowWidth < mediumBreakpoint ? (
              <Box position="relative" mt="24" mb="20">
                <Box height="2px" bg="gray1000" />
                <Box
                  position="absolute"
                  top="-20px"
                  left="calc(50% - 20px)"
                  width="10"
                  height="10"
                  borderRadius="full"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  bg="gray1000"
                >
                  <P
                    mb={0}
                    typeStyle="labelMedium"
                    textAlign="center"
                    color="white"
                  >
                    OR
                  </P>
                </Box>
              </Box>
            ) : (
              <Box position="relative" height="552px" px="8">
                <Box width="2px" height="100%" bg="gray1000" />
                <Box
                  position="absolute"
                  top="calc(50% - 20px)"
                  left="14px"
                  width="10"
                  height="10"
                  borderRadius="full"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  bg="gray1000"
                >
                  <P
                    mb={0}
                    typeStyle="labelMedium"
                    textAlign="center"
                    color="white"
                  >
                    OR
                  </P>
                </Box>
              </Box>
            )}
            <Box width={[null, null, '456px']} mt="14">
              <Box
                display="flex"
                height="375px"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <img
                    src={phone}
                    alt="hand holding phone"
                    css={`
                      display: block;
                      margin: 4px 0 20px 0;
                    `}
                  />
                  <H3
                    className="h4"
                    typeStyle="h4"
                    css={css({ mt: 7, textAlign: 'center' })}
                  >
                    Call Us
                  </H3>
                  <Pill bg="lightBlue1000">
                    <P
                      mb={0}
                      typeStyle="labelSmall"
                      textAlign="center"
                      color="#FFFFFF"
                    >
                      {phoneDisabled ? 'Closed' : 'Open'}
                    </P>
                  </Pill>
                  <P
                    mb={0}
                    typeStyle="bodyLarge"
                    textAlign="center"
                    color="gray600"
                    display="block"
                    mt="6"
                  >
                    Mon. through Fri. 8:00 AM - 9:00 PM EST
                    <br />
                    Sat. 9:00 AM - 6:00 PM EST
                    <br />
                    Closed Sun. and holidays
                  </P>
                </Box>
                <Box textAlign="center">
                  <ButtonAnchor
                    outlined
                    id="supportPage__Button--CallUs"
                    display="inline-block"
                    rounded
                    target="_blank"
                    rel="noreferrer"
                    href="tel:(800) 998-0762"
                    disabled={phoneDisabled}
                    aria-label="Call Us, 800 998 0762"
                  >
                    (800) 998-0762
                  </ButtonAnchor>
                </Box>
              </Box>
              {phoneDisabled && (
                <P
                  mb={0}
                  mt="4"
                  display="block"
                  textAlign="center"
                  typeStyle="bodySmall"
                  id="supportPage--text--callSupportClosedMessage"
                >
                  {`Call support will open ${phoneWillOpenTime}`}
                </P>
              )}
            </Box>
          </Box>
        </Box>
        <Box mx="auto" id="ctaBox" position="relative" zIndex="5">
          <Box
            width={['86%', '86%', '90%']}
            py="16"
            pl={['10', '10', '19']}
            pr={['10', '10', '24']}
            bg="lightBlue200"
            borderRadius="3"
            display={[null, null, 'flex']}
            mx="auto"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display={[null, null, 'flex']} alignItems="center">
              <Icon
                src={Leave}
                color="gray1000"
                fontSize="48px"
                width={['100%', '100%', 'inherit']}
                display={['flex', 'flex', 'block']}
                justifyContent="center"
              />
              <P
                mb={0}
                typeStyle="headlineMedium"
                display="block"
                color="gray1000"
                fontWeight="900"
                textAlign={['center', 'center', 'left']}
                mt={['6', '6', '0']}
                ml={[null, null, '19']}
                width={[null, null, '65%']}
                as="h2"
              >
                Learn what happens after you submit your insurance details.
              </P>
            </Box>
            <Box
              textAlign={['center', 'center', 'right']}
              mt={['11', '11', '0']}
            >
              <Button
                // id="Support__CTASection__Button--learnMore"
                className="Support__CTASection__Button--learnMoreAnchorLink"
                rounded
                forwardedAs={AnchorLink}
                to="/support/#iSubmittedMyInformationWhatHappensNext"
                boxShadow="blue"
                fontSize="16px"
                px="26px"
                css={`
                  white-space: nowrap;
                `}
              >
                Learn More
              </Button>
            </Box>
          </Box>
          <Box
            width={['76%', '76%', '80%']}
            height="24px"
            borderBottomLeftRadius="3"
            borderBottomRightRadius="3"
            mx="auto"
            bg="lightBlue200"
            opacity="50%"
          />
        </Box>
        <AffinityProvider theme={dark}>
          <Box
            bg="background"
            pt={`${(ctaHeight + 24) / 2}px`}
            mt={`${(-ctaHeight - 24) / 2}px`}
            pb="14"
            color="white"
            textAlign="center"
            className="supportFooter"
            position="relative"
          >
            <Container px={8}>
              <BottomOfFooter />
            </Container>
          </Box>
        </AffinityProvider>
      </main>
    </Layout>
  )
}

const graphqlQuery = graphql`
  {
    support: mdx(slug: { eq: "support" }) {
      frontmatter {
        FAQs {
          answer
          question
        }
        metaTitle
        metaDescription
      }
    }
    articles: allMdx(filter: { fileAbsolutePath: { glob: "**/support/*" } }) {
      nodes {
        frontmatter {
          title
          path
        }
      }
    }
  }
`

export default SupportPage
export { graphqlQuery }
